import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("systemDetails")
  ? JSON.parse(localStorage.getItem("systemDetails"))
  : {
      os: "",
      browser:"",
      product:""
    };
const systemDetailsSlice = createSlice({
  name: "systemDetails",
  initialState,
  reducers: {
    setSystemDetails: (state, action) => {
      state.os = action.payload.os;
      state.browser = action.payload.browser;
      state.product = action.payload.product
      localStorage.setItem("systemDetails", JSON.stringify(action.payload));
    },
  },
});

export const { setSystemDetails } = systemDetailsSlice.actions;

export const fetchCompanyDetails = (state) => state.companyDetails;

export default systemDetailsSlice.reducer;
