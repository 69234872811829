import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import userReducer from "../slices/user/userSlice";
import generalSettingsReducer from "../slices/generalSettings/generalSettingsSlice";
import companyDetailsReducer from "../slices/companyDetails/companyDetailsSlice";
import financialYearReducer from "../slices/financialYear/financialYearSlice";
import userTableReducer from "../slices/userTable/userTableSlice";
import userRoleSettingsReducer from "../slices/userRoleSettings/userRoleSettingsSlice";
import branchSettingsReducer from "../slices/branchSettings/branchSettingsSlice";
import languageReducer from "../slices/language/languageSlice";
import submitModeReducer from "../slices/submitModes/submitModeSlice";
import sales_filterReducer from "../slices/sales_order_filter/salesFilter";
import versionSliceReducer from "../slices/version/versionSlice";
import companyUrlReducer from "../slices/companyUrlSettings/companyUrlSettingsSlice";
import systemDetailsSlice from "../slices/systemDetails/systemDetailsSlice"
export default configureStore({
  reducer: {
    user: userReducer,
    generalSettings: generalSettingsReducer,
    companyDetails: companyDetailsReducer,
    financialYear: financialYearReducer,
    userTable: userTableReducer,
    userRollSettings: userRoleSettingsReducer,
    branchSettings: branchSettingsReducer,
    language: languageReducer,
    submitMode: submitModeReducer,
    versionDetails: versionSliceReducer,
    companyUrl: companyUrlReducer,
    filter:sales_filterReducer,
    systemDetails:systemDetailsSlice
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
  // devTools: false,
});
