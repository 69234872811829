import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("generalSettings")
  ? JSON.parse(localStorage.getItem("generalSettings"))
  : {
      // general => general
      BlockTransactionsByDate: false,
      EnableCardDetails: false,
      EnableCardNetWork: false,
      EnableLoyaltySettings: false,
      ShowInactiveUsers: false,
      PostDateTransaction: "",
      PreDateTransaction: "",
      ShowDiscountInPayments: false,
      ShowDiscountInReceipts: false,
      EnableBillwise: false,
      // general => decimal points
      PriceDecimalPoint: "",
      QtyDecimalPoint: "",
      // inventory => general
      CreateBatchForWorkOrder: false,
      ShowAllProductsInWorkOrder: false,
      // ShowAllProductsInWorkOrder: false,
      EnableProductBatchWise: false,
      EnableDuplicateProductName: false,
      EnableWarehouse: false,
      EnableCreditPeriod: false,
      show_productcode_InsearchSale: false,
      show_productcode_InsearchPurchase: false,
      show_stock_InsearchSale: false,
      show_stock_InsearchPurchase: false,
      show_purchasePrice_InsearchSale: false,
      show_salesPrice_InsearchPurchase: false,
      EnableTransilationInProduct: false,
      InclusiveRateWorkOrder: false,
      MultiUnit: false,
      Negative_Stock_Show: false,
      PriceCategory: false,
      ShowWarrantyPeriodInProduct: false,
      ShowYearMonthCalanderInWorkOrder: false,
      VoucherNoAutoGenerate: false,
      BatchCriteria: "PurchasePrice",
      validationSerialNumber: "Ignore",
      SalesPriceLessThanMinimumSalesPrice: "Ignore",
      // inventory => general => loyalty settings
      is_Loyalty_SalesReturn_MinimumSalePrice: false,
      // inventory => sales
      AllowAdvanceReceiptinSales: false,
      AllowCashReceiptMoreSaleAmt: false,
      AllowNegativeStockSales: false,
      AllowNegativeStockInStockTransfer: false,
      AllowQtyDividerinSales: false,
      AllowUpdateBatchPriceInSales: false,
      BlockSalesPrice: false,
      ShowProfitinSalesRegisterReport: false,
      ShowCustomerLastSalesPriceinSales: false,
      ShowSupplierLastPurchasePriceinPurchase: false,
      EnableItemCodeNoInSales: false,
      EnableCreditLimit: false,
      EnableFaeraSettings: false,
      EnableSalesManInSales: false,
      EnableSerialNoInSales: false,
      EnableShippingCharge: false,
      AutoIncrimentQty: false,
      QuantityincrementAlert: false,
      AllowNegativeStockInSalesOrder: false,
      AllowNegativeStockInSalesEstimate: false,
      Free_Quantity_In_Sales: false,
      InclusiveRateSales: false,
      SalesPriceUpdate: false,
      SalesPriceUpdateMultiUnit: false,
      ShowDescriptionInSales: false,
      ShowDiscountInSales: false,
      ShowEmployeesInSales: false,
      ShowNegativeBatchInSales: false,
      ShowSettingsinSales: false,
      ShowSupplierInSales: false,
      blockSaleByBillDisct: false,
      RoundOffSales: "",
      show_brand_InsearchSale:false,
      // inventory => purchase
      AllowAdvanceReceiptinPurchase: false,
      AllowCashReceiptMorePurchaseAmt: false,
      Free_Quantity_In_Purchase: false,
      InclusiveRatePurchase: false,
      PurchasePriceUpdate: false,
      PurchasePriceUpdateMultiUnit: false,
      ReferenceBillNo: false,
      ShowCustomerInPurchase: false,
      ShowDiscountInPurchase: false,
      ShowManDateAndExpDatePurchase: false,
      ShowSalesPriceInPurchase: false,
      ShowMaximumStockAlert: false,
      ShowSettingsinPurchase: false,
      RoundOffPurchase: "",
      // ---------------------------------------------------------

      Tax1: false,
      Tax2: false,
      Tax3: false,
      Additional_Discount: false,
      Bill_Discount: false,
      Increment_Qty_In_POS: false,
      Kitchen_Print: false,
      Print_After_Save_Active: false,
      Order_Print: false,
      Business_Type: false,
      VAT: false,
      GST: false,
      KFC: false,
      RoundingFigure: "",
      Show_Sales_Type: false,
      Show_Purchase_Type: false,
      Purchase_GST_Type: "",
      Sales_GST_Type: "",
      Sales_VAT_Type: "",
      Purchase_VAT_Type: "",
      Purchase_VAT_Type_name: "",
      Sales_VAT_Type_name: "",
      Sales_GST_Type_name: "",
      Purchase_GST_Type_name: "",
      CustomerBasedPrint: false,
      Loyalty_Point_Expire: false,
      loyalty_PointValue: "",
      AllowExtraSerielNos: false,
      ShowDueBalanceInSales: false,
      ShowDueBalanceInPurchase: false,
      EnableVoucherNoUserWise: false,
      ShowPositiveStockInSales: false,
      EnableDeliveryDateInSales: false,
      EnableRoute:false,
      ShowCustomerCode:false,
      EnableForeignCurrency:false,
      ShowExpenseInStockValue:false,
      EnableAdditionalExpense:false,
      EnableBranch:false,
      EnableVbassist:false,
      UpdationDelayCount:0,
      EnableExciseTax:false,
      ShowCostWithExpenseAsPurchasePrice:false,
      ShowAverageCostInSales:false,
      AvoidLedgerwiseFilterInSalesHistory:false,
      ShowLedgerWisePurchaseHistory : false,
      ShowSalesManInReceipt :false,
      BlockSalesBelowPurchase :false,
      EnableEinvoiceKSA:false,
      UnitPriceUpdatebyItemWiseDiscount: false,
      EstimateConvertion: "Ignore",
      AutoReferenceNoReceiptAndPayment:false,
      CurrencyFormat: "None"
    };

const generalSettingsSlice = createSlice({
  name: "generalSettings",
  initialState,
  reducers: {
    generalSettingsSuccess: (state, action) => {
      console.log(state);
      state.access = action.payload.access;
      // general => general
      state.BlockTransactionsByDate = action.payload.BlockTransactionsByDate;
      state.EnableCardDetails = action.payload.EnableCardDetails;
      state.EnableCardNetWork = action.payload.EnableCardNetWork;
      state.EnableLoyaltySettings = action.payload.EnableLoyaltySettings;
      state.ShowInactiveUsers = action.payload.ShowInactiveUsers;
      state.PostDateTransaction = action.payload.PostDateTransaction;
      state.PreDateTransaction = action.payload.PreDateTransaction;
      state.ShowDiscountInPayments = action.payload.ShowDiscountInPayments;
      state.ShowDiscountInReceipts = action.payload.ShowDiscountInReceipts;
      state.EnableBillwise = action.payload.EnableBillwise;
      state.validationSerialNumber=action.payload.validationSerialNumber;
      // general => decimal points
      state.PriceDecimalPoint = action.payload.PriceDecimalPoint;
      state.QtyDecimalPoint = action.payload.QtyDecimalPoint;
      // inventory => general
      state.CreateBatchForWorkOrder = action.payload.CreateBatchForWorkOrder;
      state.ShowAllProductsInWorkOrder =
        action.payload.ShowAllProductsInWorkOrder;
      state.ShowAllProductsInWorkOrder =
        action.payload.ShowAllProductsInWorkOrder;
      state.EnableProductBatchWise = action.payload.EnableProductBatchWise;
      state.EnableDuplicateProductName =
        action.payload.EnableDuplicateProductName;
      state.EnableWarehouse = action.payload.EnableWarehouse;
      state.EnableCreditPeriod = action.payload.EnableCreditPeriod;
      state.show_productcode_InsearchSale =
        action.payload.show_productcode_InsearchSale;
      state.show_productcode_InsearchPurchase =
        action.payload.show_productcode_InsearchPurchase;
      state.show_stock_InsearchSale = action.payload.show_stock_InsearchSale;
      state.show_stock_InsearchPurchase =
        action.payload.show_stock_InsearchPurchase;
      state.show_purchasePrice_InsearchSale =
        action.payload.show_purchasePrice_InsearchSale;
      state.show_salesPrice_InsearchPurchase =
        action.payload.show_salesPrice_InsearchPurchase;
      state.EnableTransilationInProduct =
        action.payload.EnableTransilationInProduct;
      state.InclusiveRateWorkOrder = action.payload.InclusiveRateWorkOrder;
      state.MultiUnit = action.payload.MultiUnit;
      state.Negative_Stock_Show = action.payload.Negative_Stock_Show;
      state.PriceCategory = action.payload.PriceCategory;
      state.ShowWarrantyPeriodInProduct =
        action.payload.ShowWarrantyPeriodInProduct;
      state.ShowYearMonthCalanderInWorkOrder =
        action.payload.ShowYearMonthCalanderInWorkOrder;
      state.VoucherNoAutoGenerate = action.payload.VoucherNoAutoGenerate;
      state.BatchCriteria = action.payload.BatchCriteria;
      state.SalesPriceLessThanMinimumSalesPrice = action.payload.SalesPriceLessThanMinimumSalesPrice;
      // inventory => general => loyalty settings
      state.is_Loyalty_SalesReturn_MinimumSalePrice =
        action.payload.is_Loyalty_SalesReturn_MinimumSalePrice;
      // inventory => sales
      state.AllowAdvanceReceiptinSales =
        action.payload.AllowAdvanceReceiptinSales;
      state.AllowCashReceiptMoreSaleAmt =
        action.payload.AllowCashReceiptMoreSaleAmt;
      state.AllowNegativeStockSales = action.payload.AllowNegativeStockSales;
      state.AllowNegativeStockInStockTransfer =
        action.payload.AllowNegativeStockInStockTransfer;
      state.AllowQtyDividerinSales = action.payload.AllowQtyDividerinSales;
      state.AllowUpdateBatchPriceInSales =
        action.payload.AllowUpdateBatchPriceInSales;
      state.BlockSalesPrice = action.payload.BlockSalesPrice;
      state.ShowProfitinSalesRegisterReport =
        action.payload.ShowProfitinSalesRegisterReport;

      state.ShowCustomerLastSalesPriceinSales =
        action.payload.ShowCustomerLastSalesPriceinSales;
      state.ShowSupplierLastPurchasePriceinPurchase =
        action.payload.ShowSupplierLastPurchasePriceinPurchase;

      state.EnableItemCodeNoInSales = action.payload.EnableItemCodeNoInSales;
      state.EnableCreditLimit = action.payload.EnableCreditLimit;
      state.EnableFaeraSettings = action.payload.EnableFaeraSettings;
      state.EnableSalesManInSales = action.payload.EnableSalesManInSales;
      state.EnableSerialNoInSales = action.payload.EnableSerialNoInSales;
      state.EnableShippingCharge = action.payload.EnableShippingCharge;
      state.AutoIncrimentQty = action.payload.AutoIncrimentQty;
      state.QuantityincrementAlert = action.payload.QuantityincrementAlert;
      state.AllowNegativeStockInSalesOrder = action.payload.AllowNegativeStockInSalesOrder;
      state.AllowNegativeStockInSalesEstimate = action.payload.AllowNegativeStockInSalesEstimate;
      state.Free_Quantity_In_Sales = action.payload.Free_Quantity_In_Sales;
      state.InclusiveRateSales = action.payload.InclusiveRateSales;
      state.SalesPriceUpdate = action.payload.SalesPriceUpdate;
      state.SalesPriceUpdateMultiUnit = action.payload.SalesPriceUpdateMultiUnit;
      state.ShowDescriptionInSales = action.payload.ShowDescriptionInSales;
      state.ShowDiscountInSales = action.payload.ShowDiscountInSales;
      state.ShowEmployeesInSales = action.payload.ShowEmployeesInSales;
      state.ShowNegativeBatchInSales = action.payload.ShowNegativeBatchInSales;
      state.ShowSettingsinSales = action.payload.ShowSettingsinSales;
      state.ShowSupplierInSales = action.payload.ShowSupplierInSales;
      state.blockSaleByBillDisct = action.payload.blockSaleByBillDisct;
      state.RoundOffSales = action.payload.RoundOffSales;
      state.show_brand_InsearchSale=action.payload.show_brand_InsearchSale;
      // inventory => purchase
      state.AllowAdvanceReceiptinPurchase =
        action.payload.AllowAdvanceReceiptinPurchase;
      state.AllowCashReceiptMorePurchaseAmt =
        action.payload.AllowCashReceiptMorePurchaseAmt;
      state.Free_Quantity_In_Purchase =
        action.payload.Free_Quantity_In_Purchase;
      state.InclusiveRatePurchase = action.payload.InclusiveRatePurchase;
      state.PurchasePriceUpdate = action.payload.PurchasePriceUpdate;
      state.PurchasePriceUpdateMultiUnit = action.payload.PurchasePriceUpdateMultiUnit;
      state.ReferenceBillNo = action.payload.ReferenceBillNo;
      state.ShowCustomerInPurchase = action.payload.ShowCustomerInPurchase;
      state.ShowDiscountInPurchase = action.payload.ShowDiscountInPurchase;
      state.ShowManDateAndExpDatePurchase =
        action.payload.ShowManDateAndExpDatePurchase;
      state.ShowSalesPriceInPurchase = action.payload.ShowSalesPriceInPurchase;
      state.ShowMaximumStockAlert = action.payload.ShowMaximumStockAlert;
      state.ShowSettingsinPurchase = action.payload.ShowSettingsinPurchase;
      state.RoundOffPurchase = action.payload.RoundOffPurchase;
      // ---------------------------------------------------------

      state.Tax1 = action.payload.Tax1;
      state.Tax2 = action.payload.Tax2;
      state.Tax3 = action.payload.Tax3;
      state.Additional_Discount = action.payload.Additional_Discount;
      state.Bill_Discount = action.payload.Bill_Discount;
      state.Increment_Qty_In_POS = action.payload.Increment_Qty_In_POS;
      state.Kitchen_Print = action.payload.Kitchen_Print;
      state.Print_After_Save_Active = action.payload.Print_After_Save_Active;
      state.Order_Print = action.payload.Order_Print;
      state.Business_Type = action.payload.Business_Type;
      state.VAT = action.payload.VAT;
      state.GST = action.payload.GST;
      state.KFC = action.payload.KFC;
      state.RoundingFigure = action.payload.RoundingFigure;
      state.Show_Sales_Type = action.payload.Show_Sales_Type;
      state.Show_Purchase_Type = action.payload.Show_Purchase_Type;
      state.Purchase_GST_Type = action.payload.Purchase_GST_Type;
      state.Sales_GST_Type = action.payload.Sales_GST_Type;
      state.Sales_VAT_Type = action.payload.Sales_VAT_Type;
      state.Purchase_VAT_Type = action.payload.Purchase_VAT_Type;
      state.Purchase_VAT_Type_name = action.payload.Purchase_VAT_Type_name;
      state.Sales_VAT_Type_name = action.payload.Sales_VAT_Type_name;
      state.Sales_GST_Type_name = action.payload.Sales_GST_Type_name;
      state.Purchase_GST_Type_name = action.payload.Purchase_GST_Type_name;
      state.CustomerBasedPrint = action.payload.CustomerBasedPrint;
      state.Loyalty_Point_Expire = action.payload.Loyalty_Point_Expire;
      state.loyalty_PointValue = action.payload.loyalty_PointValue;
      state.AllowExtraSerielNos = action.payload.AllowExtraSerielNos;
      state.ShowDueBalanceInSales = action.payload.ShowDueBalanceInSales;
      state.ShowDueBalanceInPurchase = action.payload.ShowDueBalanceInPurchase;
      state.EnableVoucherNoUserWise = action.payload.EnableVoucherNoUserWise;
      state.ShowPositiveStockInSales = action.payload.ShowPositiveStockInSales;
      state.EnableRoute = action.payload.EnableRoute;
      state.ShowCustomerCode = action.payload.ShowCustomerCode;
      state.EnableForeignCurrency = action.payload.EnableForeignCurrency;
      state.ShowExpenseInStockValue = action.payload.ShowExpenseInStockValue;
      state.EnableAdditionalExpense = action.payload.EnableAdditionalExpense;
      state.EnableDeliveryDateInSales =
        action.payload.EnableDeliveryDateInSales;
      state.PurchasePriceInSales  =action.payload.PurchasePriceInSales;
      state.EnableBranch  =action.payload.EnableBranch;
      state.EnableVbassist = action.payload.EnableVbassist;
      state.UpdationDelayCount = action.payload.UpdationDelayCount;
      state.EnableExciseTax=action.payload.EnableExciseTax;
      state.ShowCostWithExpenseAsPurchasePrice=action.payload.ShowCostWithExpenseAsPurchasePrice;
      state.ShowAverageCostInSales=action.payload.ShowAverageCostInSales;
      state.AvoidLedgerwiseFilterInSalesHistory=action.payload.AvoidLedgerwiseFilterInSalesHistory;
      state.ShowLedgerWisePurchaseHistory=action.payload.ShowLedgerWisePurchaseHistory;
      state.ShowSalesManInReceipt=action.payload.ShowSalesManInReceipt;
      state.BlockSalesBelowPurchase=action.payload.BlockSalesBelowPurchase;
      state.EnableEinvoiceKSA = action.payload.EnableEinvoiceKSA;
      state.UnitPriceUpdatebyItemWiseDiscount=action.payload.UnitPriceUpdatebyItemWiseDiscount;
      state.EstimateConvertion = action.payload.EstimateConvertion;
      state.AutoReferenceNoReceiptAndPayment = action.payload.AutoReferenceNoReceiptAndPayment
      state.CurrencyFormat = action.payload.CurrencyFormat;
      localStorage.setItem("generalSettings", JSON.stringify(action.payload));
    },
    generalSettingsLogoutSuccess: (state, action) => {
      state.access = null;
      // general => general
      state.BlockTransactionsByDate = null;
      state.EnableCardDetails = null;
      state.EnableCardNetWork = null;
      state.EnableLoyaltySettings = null;
      state.ShowInactiveUsers = null;
      state.PostDateTransaction = null;
      state.PreDateTransaction = null;
      state.ShowDiscountInPayments = null;
      state.ShowDiscountInReceipts = null;
      state.EnableBillwise = null;
      // general => decimal points
      state.PriceDecimalPoint = null;
      state.QtyDecimalPoint = null;
      // inventory => general
      state.CreateBatchForWorkOrder = null;
      state.ShowAllProductsInWorkOrder = null;
      state.ShowAllProductsInWorkOrder = null;
      state.EnableProductBatchWise = null;
      state.EnableDuplicateProductName = null;
      state.EnableWarehouse = null;
      state.EnableCreditPeriod = null;
      state.show_productcode_InsearchSale = null;
      state.show_productcode_InsearchPurchase = null;
      state.show_stock_InsearchSale = null;
      state.show_stock_InsearchPurchase = null;
      state.show_purchasePrice_InsearchSale = null;
      state.show_salesPrice_InsearchPurchase = null;
      state.EnableTransilationInProduct = null;
      state.InclusiveRateWorkOrder = null;
      state.MultiUnit = null;
      state.Negative_Stock_Show = null;
      state.PriceCategory = null;
      state.ShowWarrantyPeriodInProduct = null;
      state.ShowYearMonthCalanderInWorkOrder = null;
      state.VoucherNoAutoGenerate = null;
      state.BatchCriteria = null;
      state.SalesPriceLessThanMinimumSalesPrice = null;
      // inventory => general => loyalty settings
      state.is_Loyalty_SalesReturn_MinimumSalePrice = null;
      // inventory => sales
      state.AllowAdvanceReceiptinSales = null;
      state.AllowCashReceiptMoreSaleAmt = null;
      state.AllowNegativeStockSales = null;
      state.AllowNegativeStockInStockTransfer = null;
      state.AllowQtyDividerinSales = null;
      state.AllowUpdateBatchPriceInSales = null;
      state.BlockSalesPrice = null;
      state.ShowProfitinSalesRegisterReport = null;
      state.ShowCustomerLastSalesPriceinSales = null;
      state.ShowSupplierLastPurchasePriceinPurchase = null;
      state.EnableItemCodeNoInSales = null;
      state.EnableCreditLimit = null;
      state.EnableFaeraSettings = null;
      state.EnableSalesManInSales = null;
      state.EnableSerialNoInSales = null;
      state.EnableShippingCharge = null;
      state.AutoIncrimentQty = null;
      state.QuantityincrementAlert = null;
      state.AllowNegativeStockInSalesOrder = null;
      state.AllowNegativeStockInSalesEstimate = null;
      state.Free_Quantity_In_Sales = null;
      state.InclusiveRateSales = null;
      state.SalesPriceUpdate = null;
      state.SalesPriceUpdateMultiUnit = null;
      state.ShowDescriptionInSales = null;
      state.ShowDiscountInSales = null;
      state.ShowEmployeesInSales = null;
      state.ShowNegativeBatchInSales = null;
      state.ShowSettingsinSales = null;
      state.ShowSupplierInSales = null;
      state.blockSaleByBillDisct = null;
      state.RoundOffSales = null;
      state.show_brand_InsearchSale=null;
      // inventory => purchase
      state.AllowAdvanceReceiptinPurchase = null;
      state.AllowCashReceiptMorePurchaseAmt = null;
      state.Free_Quantity_In_Purchase = null;
      state.InclusiveRatePurchase = null;
      state.PurchasePriceUpdate = null;
      state.PurchasePriceUpdateMultiUnit = null;
      state.ReferenceBillNo = null;
      state.ShowCustomerInPurchase = null;
      state.ShowDiscountInPurchase = null;
      state.ShowManDateAndExpDatePurchase = null;
      state.ShowSalesPriceInPurchase = null;
      state.ShowMaximumStockAlert = null;
      state.ShowSettingsinPurchase = null;
      state.RoundOffPurchase = null;
      // ---------------------------------------------------------

      state.Tax1 = null;
      state.Tax2 = null;
      state.Tax3 = null;
      state.Additional_Discount = null;
      state.Bill_Discount = null;
      state.Increment_Qty_In_POS = null;
      state.Kitchen_Print = null;
      state.Print_After_Save_Active = null;
      state.Order_Print = null;
      state.Business_Type = null;
      state.VAT = null;
      state.GST = null;
      state.KFC = null;
      state.RoundingFigure = null;
      state.Show_Sales_Type = null;
      state.Show_Purchase_Type = null;
      state.Purchase_GST_Type = null;
      state.Sales_GST_Type = null;
      state.Sales_VAT_Type = null;
      state.Purchase_VAT_Type = null;
      state.Purchase_VAT_Type_name = null;
      state.Sales_VAT_Type_name = null;
      state.Sales_GST_Type_name = null;
      state.Purchase_GST_Type_name = null;
      state.CustomerBasedPrint = null;
      state.Loyalty_Point_Expire = null;
      state.loyalty_PointValue = null;
      state.AllowExtraSerielNos = null;
      state.ShowDueBalanceInSales = null;
      state.ShowDueBalanceInPurchase = null;
      state.EnableVoucherNoUserWise = null;
      state.ShowPositiveStockInSales = null;
      state.EnableDeliveryDateInSales = null;
      state.EnableRoute = null;
      state.ShowCustomerCode=null;
      state.EnableForeignCurrency=null;
      state.ShowExpenseInStockValue=null;
      state.EnableAdditionalExpense=null;
      state.PurchasePriceInSales =null;
      state.EnableBranch =null;
      state.EnableVbassist = null;
      state.validationSerialNumber =null;
      state.UpdationDelayCount = null;
      state.EnableExciseTax = null;
      state.ShowCostWithExpenseAsPurchasePrice = null;
      state.ShowAverageCostInSales = null;
      state.AvoidLedgerwiseFilterInSalesHistory = null;
      state.ShowLedgerWisePurchaseHistory = null;
      state.ShowSalesManInReceipt = null;
      state.BlockSalesBelowPurchase = null;
      state.EnableEinvoiceKSA = null;
      state.UnitPriceUpdatebyItemWiseDiscount=null;
      state.EstimateConvertion = null;
      state.AutoReferenceNoReceiptAndPayment = null;
      state.CurrencyFormat = null;
      localStorage.removeItem("generalSettings");
    },
  },
});

export const { generalSettingsSuccess, generalSettingsLogoutSuccess } =
  generalSettingsSlice.actions;
export const DeliveryDateInSales = (state) =>
  state.generalSettings.EnableDeliveryDateInSales;

// export const selectUserName = (state) => state.generalSettings.name;
// export const selectUserEmail = (state) => state.generalSettings.email;
// export const selectUserPhoto = (state) => state.generalSettings.photo;
// export const accessToken = (state) => state.generalSettings.access;
// export const selectAuthenticated = (state) => state.generalSettings.isAuth;

export default generalSettingsSlice.reducer;
